<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="backDrop">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full"
              :class="{
                'sm:max-w-6xl': size_large,
                'sm:max-w-4xl': size_normal,
                'sm:max-w-lg': size_small,
              }"
            >
              <div
                class="flex justify-between items-start p-4 px-4 sm:px-6 lg:px-8 rounded-t border-b mb-5"
              >
                <!-- Modal Header -->
                <slot name="header">
                  <h3
                    class="text-xl font-semibold text-gray-900 dark:text-white"
                  >
                    Modal Header
                  </h3>
                  <button
                    type="button"
                    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    @click="closeModal"
                  >
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </slot>
              </div>
              <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <!-- Modal Body -->
                <slot name="body">
                  <p>Modal Body</p>
                </slot>
              </div>
              <!-- Modal Footer -->
              <div
                class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse border-t border-gray-200 p-4 px-4 sm:px-6 lg:px-8"
              >
                <slot name="footer">
                  <button
                    type="button"
                    class="inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    :class="[
                      submitButtonColor === 'red'
                        ? 'bg-red-600  hover:bg-red-700 focus:ring-red-500'
                        : 'bg-indigo-600  hover:bg-indigo-700 focus:ring-indigo-500',
                    ]"
                    @click="submitHandler"
                  >
                    {{ submitButtonLabel }}
                  </button>
                  <button
                    v-if="!hideCancelButton"
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    @click="cancelHandler"
                  >
                    {{ cancelButtonLabel }}
                  </button>
                </slot>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, reactive } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { computed } from "@vue/reactivity";

const props = defineProps({
  size: { type: String, default: "normal" },
  cancelButtonLabel: { type: String, default: "取消" },
  cancelHandler: { type: Function, default: () => {} },
  hideCancelButton: { type: Boolean, default: false },
  submitButtonLabel: { type: String, default: "実行" },
  submitButtonColor: { type: String, default: "indigo" },
  submitHandler: { type: Function, default: () => {} },
  disableBackDrop: { type: Boolean, default: false },
});

const size_small = computed({
  get: function () {
    return props.size === "small";
  },
});

const size_normal = computed({
  get: function () {
    return props.size === "normal";
  },
});

const size_large = computed({
  get: function () {
    return props.size === "large";
  },
});

const open = ref(false);

function showModal() {
  open.value = true;
}

function hideModal() {
  open.value = false;
}

function backDrop() {
  if (props.disableBackDrop) return;
  hideModal();
}

function submitHandler() {
  props.submitHandler();
}

function cancelHandler() {
  props.cancelHandler();
}

defineExpose({
  showModal,
  hideModal,
});
</script>
